import { createGlobalStyle } from "styled-components";
import bg from "../assets/images/bg.svg";
import stripes from "../assets/images/stripes.svg";

const GlobalStyles = createGlobalStyle`
  :root {
    --red: #FF4949;
    --black: #2E2E2E;
    --yellow: #ffc600;
    --white: #fff;
    --off-white: #f5f8fa;
    --grey: #efefef;
    --gray: #58647b;
    --light-gray: #cbd6e2;

    --light-blue: #2cb8e1;
    --blue: #145eda;
    --dark-blue: #2D3E50;

    --green: #2fcd71;
    

    --container-block-padding: 1.5rem;
    --container-inline-padding: 3.3333vw;

    --block-element-padding: 9px 15px;

    --max-width: 1200px;

    --box-shadow-color: #cbd6e2;
    --box-shadow-sm: 0 0 5px var(--box-shadow-color);
    --box-shadow: 0 0 15px var(--box-shadow-color);
    --box-shadow-lg: 0 0 25px var(--box-shadow-color);
  }
  html {
    // background-image: url(${bg});
    // background-size: 450px;
    // background-attachment: fixed;
    font-size: 10px;
    background: var(--off-white);
    min-width: 375px;
  }

  body {
    font-size: 2rem;
  }
  body.mobile-drawer--open {
    height: 100%;
    overflow: hidden;
  }
  
  #gatsby-focus-wrapper {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }

  fieldset {
    border-color: rgba(0,0,0,0.1);
    border-width: 1px;
  }

  button,
  .button {
    border: 0;
    padding: var(--block-element-padding);
    border-radius: 3px;
    cursor: pointer;
    text-shadow: 0.5px 0.5px 0 rgba(0,0,0,0.2);
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    
    &.cast {
      --cast: 2px;
      box-shadow: var(--cast) var(--cast) 0 var(--grey);
      transition: all 0.2s;
      
      &:hover {
        --cast: 4px;
      }
    }

    &.themed {
      background: var(--blue);
      color: white;
    }
  }


  .gatsby-image-wrapper img[src*=base64\\,] {
    image-rendering: -moz-crisp-edges;
    image-rendering: pixelated;
  }

  /* Scrollbar Styles */
  body::-webkit-scrollbar {
    width: 12px;
  }
  html {
    scrollbar-width: thin;
    scrollbar-color: var(--black) var(--white);
  }
  body::-webkit-scrollbar-track {
    background: var(--white);
  }
  body::-webkit-scrollbar-thumb {
    background-color: var(--black) ;
    border-radius: 6px;
    border: 3px solid var(--white);
  }

  hr {
    // border: 0;
    // height: 8px;
    // background-image: url(${stripes});
    // background-size: 1500px;
  }

  img {
    max-width: 100%;
  }

  .full-width {
    width: 100%;
  }

  .tilt {
    transform: rotate(-2deg);
    position: relative;
    display: inline-block;
  }

  .max-width {
    max-width: var(--max-width);
  }
  
  .page-wrapper {
    max-width: var(--max-width);
    padding: var(--container-block-padding) var(--container-inline-padding);
    margin: 0 auto;
  }

  

  @media (max-width: 1100px) {
    .page-wrapper {
      margin-left: 1.5rem;
      margin-right: 1.5rem;
    }
  }

`;

export default GlobalStyles;
