import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import Img from "gatsby-image";
import bannerImg from "../assets/images/raspberrypi.jpg";
import lfgLogoSmall from "../assets/images/lfg_logo_small.png";
import lfgLogoLarge from "../assets/images/lfg_logo_large.png";
import { FiX, FiMenu } from "react-icons/fi";
import useEventListener from "../utils/useEventListener";

const breakpointMaxWidth = 600;
const drawerBreakpoint = 800;

const NavStyles = styled.nav`
  padding: 0 var(--container-inline-padding);

  .header-full,
  .header-mobile {
    padding: var(--container-block-padding) 0;
  }

  .header-full {
    display: flex;
    flex-direction: row;
    max-width: var(--max-width);
    margin: 0 auto;
    width: 100%;
  }
  .header-mobile {
    display: none;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: var(--gray);
  }

  .mobile-drawer__container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.2);
    z-index: 98;
    display: none;

    &.open {
      display: block;
    }
  }
  .mobile-drawer__content {
    position: fixed;
    width: 250px;
    left: -250px;
    top: 0;
    bottom: 0;
    background-color: white;
    z-index: 99;
    display: none;
    transition: 0.25s all;
  }
  .mobile-drawer--open {
    left: 0;
    box-shadow: 6px -1px 14px 7px rgba(0, 0, 0, 0.15);
  }

  .banner {
    max-height: 60px;
    border-radius: 5px;
    padding: 8px;
    background: var(--dark-blue);
  }

  .banner--text {
    margin-right: 50px;
    opacity: 0.8;
    font-family: fantasy;
    transform: rotate(-5deg);
    font-size: 1.75em;
  }

  ul {
    margin: 0;
    padding: 0;
    text-align: center;
    list-style: none;
    display: flex;
    align-items: center;
  }

  .links--left {
  }
  .links--right {
    margin-left: auto;
    display: flex;
  }

  a {
    color: var(--gray);
    text-decoration: none;
    display: block;
    padding: 9px 15px;

    &:hover {
      color: var(--blue);
    }
    @media (max-width: 800px) {
      font-size: 2rem;
    }

    &.get-started {
      border: 1px solid var(--blue);
      color: var(--blue);
      border-radius: 3px;
      margin-left: 15px;

      &:hover {
        background: var(--blue);
        color: white;
        border-color: tranparent;
      }
    }

    &[aria-current="page"] {
      color: var(--black);
      font-weight: bold;

      &.get-started {
        display: none;
      }
    }
  }

  .close-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 10px;
    color: var(--gray);
  }
  .close-button {
    color: var(--gray);
    border: 0;
    outline: none;
    cursor: pointer;
  }

  @media (max-width: ${drawerBreakpoint}px) {
    // margin: 0;
    // padding: 0;

    .header-full {
      display: none;
    }
    .mobile-drawer__content {
      display: block;
    }
    .header-mobile {
      display: flex;
    }

    .links--left,
    .links--right {
      flex-direction: column;
    }
    .links--right {
      margin-top: 36px;
    }

    li {
      margin-top: 10px;
    }
    a.get-started {
      margin-left: 0;
    }
  }

  @media (max-width: ${breakpointMaxWidth}px) {
    --columns: 4;
    margin-bottom: 2rem;
    border-bottom: 2px solid var(--grey);
    // padding-bottom: 2rem;

    ul {
      grid-template-rows: auto auto;
      grid-template-columns: repeat(var(--columns), 1fr);
      justify-items: center;
    }
  }

  @media (max-width: 500px) {
    --columns: 2;
  }
`;

const Banner = ({ onClick }) => (
  <Link to="/" onClick={onClick}>
    {/* <img src={bannerImg} alt="LFG Banner" className="banner" /> */}
    {/* <img src={lfgLogoSmall} alt="LFG Banner" className="banner" /> */}
    <img src={lfgLogoLarge} alt="LFG Banner" className="banner" />
  </Link>
);

export default function Nav() {
  const [mobileDrawerOpen, setMobileDrawerOpen] = useState(false);

  const setMobileDrawerState = (shouldOpen = false) => {
    setMobileDrawerOpen(shouldOpen);

    const drawerOpenClass = "mobile-drawer--open";

    if (shouldOpen) {
      document.body.classList.add(drawerOpenClass);
    } else {
      document.body.classList.remove(drawerOpenClass);
    }
  };

  const toggleMobileDrawer = () => {
    const newDrawerState = !mobileDrawerOpen;
    setMobileDrawerState(newDrawerState);
  };

  const handleWindowResize = evt => {
    if (
      mobileDrawerOpen &&
      typeof window !== `undefined` &&
      window.innerWidth > drawerBreakpoint
    ) {
      console.log("closing");
      setMobileDrawerState(false);
    }
  };

  const closeDrawer = () => {
    setMobileDrawerState(false);
  };

  useEventListener("resize", handleWindowResize);

  const content = (
    <>
      <ul className="links--left">
        <Banner onClick={closeDrawer} />
        {/* <li className="banner--text">
          <span>Money</span>
          <br />
          <span style={{ marginRight: -56 }}>Buddy!</span>
        </li> */}
        <li>
          Welcome, <b>Jason</b>
        </li>
      </ul>

      <ul className="links--right">
        <li>
          <Link to="/resources" onClick={closeDrawer}>
            Resources
          </Link>
        </li>
        <li>
          <Link to="/contact" onClick={closeDrawer}>
            Contact Us
          </Link>
        </li>
        <li>
          <Link
            to="/upload"
            activeClassName="get-started"
            className="get-started button"
            onClick={closeDrawer}
          >
            {/* Free Account Analysis */}
            Schedule Time with an Advisor
            {/* Talk with an advisor */}
          </Link>
        </li>
      </ul>
    </>
  );

  return (
    <NavStyles>
      <div className="header-full">{content}</div>
      <div className="header-mobile">
        <Banner />

        <button className="close-button" onClick={toggleMobileDrawer}>
          <FiMenu />
        </button>
      </div>

      <div
        className={[
          "mobile-drawer__container",
          mobileDrawerOpen ? "open" : "",
        ].join(" ")}
        onClick={() => setMobileDrawerState(false)}
      ></div>
      <div
        className={[
          "mobile-drawer__content",
          mobileDrawerOpen ? "mobile-drawer--open" : "",
        ].join(" ")}
      >
        <div className="close-container">
          <button className="close-button" onClick={toggleMobileDrawer}>
            <FiX />
          </button>
        </div>
        {content}
      </div>
    </NavStyles>
  );
}
